import { CloudDownload, CloudUpload } from '@mui/icons-material';
import { Alert, Button, Grid, LinearProgress, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import React from 'react';

function App() {
    const api_endpoint        = document.location.hostname === 'localhost' ? 'http://localhost:8080' : 'https://geo-reports.mobilefuse.com';
    const [step, setStep]     = React.useState(0);
    const [bearer, setBearer] = React.useState(localStorage.getItem('bearer') ?? '');

    const [auth_loading, setAuthLoading]          = React.useState(false);
    const [auth_failed, setAuthFailed]            = React.useState(false);
    const [date_range_start, setDateRangeStart]   = React.useState('2024-02-01');
    const [date_range_end, setDateRangeEnd]       = React.useState('2024-03-01');
    const [omn, setOmn]                           = React.useState('johnson_johnson_december_2023_april_2024_109495');
    const [cmn, setCmn]                           = React.useState('');
    const [events_loading, setEventsLoading]      = React.useState(false);
    const [events_data, setEventsData]            = React.useState(null);
    const [poi_file_metadata, setPoiFileMetadata] = React.useState(null);
    const [poi_file_contents, setPoiFileContents] = React.useState(null);
    const [poi_file_headers, setPoiFileHeaders]   = React.useState(null);
    const [poi_data, setPoiData]                  = React.useState(null);
    const [poi_uploading, setPoiUploading]        = React.useState(false);
    const [lat_column, setLatColumn]              = React.useState(null);
    const [lon_column, setLonColumn]              = React.useState(null);
    const [zip_column, setZipColumn]              = React.useState(null);
    const [processing, setProcessing]             = React.useState(false);
    const [processing_data, setProcessingData]    = React.useState(null);

    async function onAuthTest() {
        setAuthLoading(true);
        setAuthFailed(false);

        const response = await fetch(`${api_endpoint}/ping`, {
            'headers': {
                'Authorization': `Bearer ${bearer}`,
            },
        });
        const status = response.status;

        if (status !== 200) {
            setAuthFailed(true);
            setAuthLoading(false);
            return;
        }

        localStorage.setItem('bearer', bearer);

        setStep(1);
        setAuthFailed(false);
        setAuthLoading(false);
    }

    async function onPullEvents() {
        setEventsLoading(true);

        const uri    = `fetch-events?date_range_start=${date_range_start}&date_range_end=${date_range_end}&omn=${omn}&cmn=${cmn}`;
        let response = await fetch(`${api_endpoint}/${uri}`, {
            'headers': {
                'Authorization': `Bearer ${bearer}`,
            },
        });
        response = await response.json();
        response['data_preview'].length = 10;

        setEventsData(response);
        setEventsLoading(false);
        setStep(2);
    }

    async function onPoiFileChange(e) {
        const file = e.target.files.item(0);
        setPoiFileMetadata(file);

        const file_contents = await file.text();
        setPoiFileContents(file_contents);

        const headers = file_contents.split(/\r?\n/)[0].split(',');
        setPoiFileHeaders(headers);

        for (const header of headers) {
            const lc = header.toLowerCase();

            if (lc.includes('lat') && !lat_column) {
                setLatColumn(header);
                continue;
            }

            if (lc.includes('lon') && !lon_column) {
                setLonColumn(header);
                continue;
            }

            if (lc.includes('zip') && !zip_column) {
                setZipColumn(header);
                continue;
            }
        }
    }

    async function uploadPoiDatabase() {
        setPoiUploading(true);

        let response = await fetch(`${api_endpoint}/upload-poi-database`, {
            'method': 'post',
            'headers': {
                'Authorization': `Bearer ${bearer}`,
                'Content-Type': 'text/csv',
            },
            'body': poi_file_contents,
        });
        response = await response.json();

        setPoiUploading(false);
        setPoiData(response);
        setStep(4);
    }

    const VisuallyHiddenInput = styled('input')({
        'clip': 'rect(0 0 0 0)',
        'clipPath': 'inset(50%)',
        'height': 1,
        'overflow': 'hidden',
        'position': 'absolute',
        'bottom': 0,
        'left': 0,
        'whiteSpace': 'nowrap',
        'width': 1,
    });

    React.useEffect(() => {
        if (step !== 5) {
            return;
        }

        // Start processing:
        setProcessing(true);

        (async() => {
            let response = await fetch(`${api_endpoint}/process`, {
                'method': 'post',
                'headers': {
                    'Authorization': `Bearer ${bearer}`,
                    'Content-Type': 'application/json',
                },
                'body': JSON.stringify({
                    's3_key_events': events_data['s3_key'],
                    's3_key_pois': poi_data['s3_key'],
                    'lat_column': lat_column,
                    'lon_column': lon_column,
                    'zip_column': zip_column,
                    'poi_headers': poi_file_headers,
                }),
            });
            response = await response.json();

            setProcessingData(response);
            setProcessing(false);
            setStep(6);
        })();
    }, [step]);

    return <Paper sx={{
        'padding': 2,
        'minWidth': '560px',
        'maxWidth': '560px',
        'justifyContent': 'center',
        'alignItems': 'center',
    }} elevation={ 0 }><Grid container rowGap={ 2 } justifyContent='center'>
        { step === 0 && <React.Fragment>
            <Typography variant='h5'>
                Please enter API credentials:
            </Typography>

            <Grid container sx={{
                    'justifyContent': 'center',
                    'px': 2,
            }}>
                <Grid item xs={ 11 }>
                    <TextField
                        variant='outlined'
                        label='Bearer Token'
                        type='password'
                        fullWidth
                        value={ bearer }
                        onChange={ (e) => setBearer(e.target.value) }
                    />
                </Grid>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Button variant='outlined' onClick={ onAuthTest } disabled={ auth_loading }>
                    Continue
                </Button>
            </Grid>

            <Grid item xs={ 12 } display={ auth_loading ? 'block' : 'none' }>
                <LinearProgress/>
            </Grid>

            <Grid item xs={ 12 } display={ auth_failed ? 'block' : 'none' }>
                <Alert severity='warning'>
                    <Typography variant='body1'>
                        Authorization has failed. Please try again.
                    </Typography>
                </Alert>
            </Grid>
        </React.Fragment> }

        { step === 1 && <React.Fragment>
            <Typography variant='h5'>
                Step 1: Select events
            </Typography>

            <Grid item xs={ 12 }>
                <Typography variant='h6' mb={ 1.5 }>
                    Select date range:
                </Typography>

                <Grid container sx={{
                    'justifyContent': 'center',
                    'columnGap': 2,
                }}>
                    <Grid item xs={ 5 }>
                        <TextField
                            variant='outlined'
                            label='From'
                            fullWidth value={ date_range_start }
                            onChange={ (e) => setDateRangeStart(e.target.value) }
                        />
                    </Grid>

                    <Grid item xs={ 5 }>
                        <TextField
                            variant='outlined'
                            label='To'
                            fullWidth
                            value={ date_range_end }
                            onChange={ (e) => setDateRangeEnd(e.target.value) }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={ 12 }>
                <Typography variant='h6' mb={ 1.5 }>
                    Select campaign:
                </Typography>

                <Grid container sx={{
                    'justifyContent': 'center',
                    'px': 2,
                }}>
                    <Grid item xs={ 11 }>
                        <TextField
                            variant='outlined'
                            label='omn'
                            fullWidth
                            value={ omn }
                            onChange={ (e) => setOmn(e.target.value) }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={ 12 }>
                <Typography variant='h6' mb={ 1.5 }>
                    Select creative (optional):
                </Typography>

                <Grid container sx={{
                    'justifyContent': 'center',
                    'px': 2,
                }}>
                    <Grid item xs={ 11 }>
                        <TextField
                            variant='outlined'
                            label='cmn'
                            fullWidth
                            value={ cmn }
                            onChange={ (e) => setCmn(e.target.value) }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Alert severity='warning'>Please note the events are pulled from Amazon Athena, which may incur an additional fee. If unsure, consult with your manager.</Alert>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Button variant='outlined' disabled={ events_loading } onClick={ onPullEvents }>I understand, continue</Button>
            </Grid>

            <Grid item xs={ 12 } display={ events_loading ? 'block' : 'none' }>
                <LinearProgress/>
            </Grid>
        </React.Fragment> }

        { step === 2 && <React.Fragment>
            <Typography variant='h5'>
                Step 2: Preview events
            </Typography>

            <Grid item xs={ 12 }>
                <TableContainer component={ Paper }>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>timestamp</TableCell>
                                <TableCell>lat</TableCell>
                                <TableCell>lon</TableCell>
                                <TableCell>event type</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {events_data['data_preview'].map((row, i) => <TableRow key={ i }>
                                <TableCell sx={{ 'textAlign': 'right' }}>{ row[0] }</TableCell>
                                <TableCell sx={{ 'textAlign': 'right' }}>{ row[1] }</TableCell>
                                <TableCell sx={{ 'textAlign': 'right' }}>{ row[2] }</TableCell>
                                <TableCell sx={{ 'textAlign': 'right' }}>{ row[3] }</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                    <Grid container alignItems='center'>
                        <Grid item xs={ 6 } sx={{
                            'px': 2,
                            'py': 2,
                        }} textAlign='center'>
                            <Typography variant='body1'>
                                <b>Total rows found:</b> { events_data['rows'] }
                            </Typography>
                        </Grid>

                        <Grid item xs={ 6 } textAlign='center'>
                            <Button
                                variant='outlined'
                                color='secondary'
                                size='small'
                                onClick={ () => window.open(events_data['s3_url'], '_blank') }>
                                    <CloudDownload/>&nbsp;Download All
                            </Button>
                        </Grid>
                    </Grid>
                </TableContainer>
            </Grid>

            <Grid item xs={ 6 } textAlign='center'>
                <Grid container gap={ 2 }>
                    <Grid item xs={ 5 }>
                        <Button variant='outlined' color='secondary' onClick={ () => setStep(1) }>Go Back</Button>
                    </Grid>

                    <Grid item xs={ 5 }>
                        <Button variant='outlined' onClick={ () => setStep(3) }>Continue</Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment> }

        { step === 3 && <React.Fragment>
            <Typography variant='h5'>
                Step 3: Upload a POI database (.csv)
            </Typography>

            <Grid item xs={ 6 } textAlign='center'>
                <Button component='label' role={ undefined } variant='outlined' tabIndex={ -1 } startIcon={ <CloudUpload/> }>
                    Select file
                    <VisuallyHiddenInput type='file' accept='text/csv' onChange={ onPoiFileChange }/>
                </Button>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Typography variant='body1'>
                    { poi_file_metadata ? `${poi_file_metadata['name']}, ${poi_file_metadata['size']} b` : 'No file selected.' }
                </Typography>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Button
                    variant='outlined'
                    disabled={ !poi_file_metadata || poi_uploading }
                    onClick={ uploadPoiDatabase }>
                        Continue
                </Button>
            </Grid>

            <Grid item xs={ 12 }>
                <LinearProgress sx={{
                    'display': poi_uploading ? 'block' : 'none',
                }}/>
            </Grid>
        </React.Fragment> }

        { step === 4 && <React.Fragment>
            <Typography variant='h5'>
                Step 4: Column mappings
            </Typography>

            <Grid item xs={ 12 }>
                <Grid container justifyContent='center'>
                    <Grid item xs={ 4 }>
                        <Typography variant='h6'>
                            Latitude column:
                        </Typography>
                    </Grid>

                    <Grid item xs={ 4 }>
                        <Typography variant='h6'>
                            Longitude column:
                        </Typography>
                    </Grid>

                    <Grid item xs={ 4 }>
                        <Typography variant='h6'>
                            Zip Code column:
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                    <Grid item xs={ 4 } display='flex' justifyContent='center'>
                        <Select size='small' value={ lat_column } label='Latitude column' onChange={ (e) => setLatColumn(e.target.value) }>
                            { poi_file_headers.map((item, i) => <MenuItem key={ i } value={ item }>{ item }</MenuItem>) }
                        </Select>
                    </Grid>

                    <Grid item xs={ 4 } display='flex' justifyContent='center'>
                        <Select size='small' value={ lon_column } label='Longitude column' onChange={ (e) => setLonColumn(e.target.value) }>
                            { poi_file_headers.map((item, i) => <MenuItem key={ i } value={ item }>{ item }</MenuItem>) }
                        </Select>
                    </Grid>

                    <Grid item xs={ 4 } display='flex' justifyContent='center'>
                        <Select size='small' value={ zip_column } label='Zip Code column' onChange={ (e) => setZipColumn(e.target.value) }>
                            { poi_file_headers.map((item, i) => <MenuItem key={ i } value={ item }>{ item }</MenuItem>) }
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={ 12 } textAlign='center' mt={ 1 }>
                <Button variant='outlined' disabled={ !lat_column || !lon_column } onClick={ () => setStep(5) }>Confirm and continue</Button>
            </Grid>
        </React.Fragment> }

        { step === 5 && <React.Fragment>
            <Typography variant='h5'>
                Step 5: Processing
            </Typography>

            <Grid item xs={ 12 }>
                <LinearProgress sx={{
                    'display': processing ? 'block' : 'none',
                }}/>
            </Grid>
        </React.Fragment> }

        { step === 6 && <React.Fragment>
            <Typography variant='h5'>
                Step 6: Summary & Download
            </Typography>

            <Grid item xs={ 12 }>
                <Typography variant='body1'>
                    ❄️ Snowflake Query: <Link href={ `https://app.snowflake.com/hga29945/qma27727/#/compute/history/queries/${processing_data['query_id']}/detail` } target='_blank'>{ processing_data['query_id'] }</Link>
                </Typography>
            </Grid>

            <Grid item xs={ 12 }>
                <Typography variant='body1'>
                    <b>Events:</b> { events_data['rows'] }
                </Typography>

                <Typography variant='body1'>
                    <b>POIs:</b> { processing_data['pois_count'] }
                </Typography>

                <Typography variant='body1'>
                    <b>Time spent:</b> { processing_data['time_elapsed'] } s
                </Typography>
            </Grid>

            <Grid item xs={ 12 } textAlign='center'>
                <Button variant='outlined' onClick={ () => window.open(processing_data['s3_url'], '_blank') }>Download Report</Button>
            </Grid>
        </React.Fragment> }
    </Grid></Paper>;
}

export default App;
