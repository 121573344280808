import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import { green, blueGrey } from '@mui/material/colors';

const root_node = document.getElementById('root');
const root = ReactDOM.createRoot(root_node);

root_node.style.position  = 'absolute';
root_node.style.left      = '50%';
root_node.style.top       = '50%';
root_node.style.transform = 'translate(-50%, -50%)';

const theme = createTheme({
    'palette': {
        'primary': green,
        'secondary': blueGrey,
    },
});

root.render(<React.StrictMode>
    <ThemeProvider theme={ theme }><App/></ThemeProvider>
</React.StrictMode>);
